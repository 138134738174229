import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./components/contact";
import Header from "./components/header";
import Home from "./components/home";
import PostComponent from "./components/post";
import Mainlayout from "./Layout/mainlayout";


const App = () => {

  return (
    <BrowserRouter>
     <Header />

      <Mainlayout>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="contact" element={<Contact/>} />
      <Route path="artical/:id" element={<PostComponent/>} />
      </Routes>
      </Mainlayout>

    </BrowserRouter>
  )
}

export default App;