import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
    name: "posts",
    initialState: {
        loading:true,
        articles:{
            intems:[]
        }


    },
    reducers: {
    }
})

export default postSlice.reducer