import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "users",
    initialState: {
       action:{}
    },
    reducers: {

    }
})

export default userSlice.reducer